import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { theme } from "../theme"
import Layout from "../components/layout"
import { createGlobalStyle } from "styled-components"
import HomeBg from "../../content/assets/img/home-bg.jpg"
import ImageComp1 from "../../content/assets/complejos/complejo1.jpg"
import ImageComp2 from "../../content/assets/complejos/complejo2.jpg"
import ImageComp3 from "../../content/assets/complejos/complejo3.jpeg"
import ImageComp5 from "../../content/assets/complejos/complejo5.jpeg"
import ImageComp6 from "../../content/assets/complejos/complejo6.jpg"
import ImageComp7 from "../../content/assets/complejos/complejo7.jpeg"
import ImageComp8 from "../../content/assets/complejos/complejo8.jpeg"
import ImageComp9 from "../../content/assets/complejos/complejo9.jpg"
import ImageComp10 from "../../content/assets/complejos/complejo10.jpeg"
import ImageComp12 from "../../content/assets/complejos/complejo12.jpeg"
import ImageComp13 from "../../content/assets/complejos/complejo13.jpeg"
import ImageComp14 from "../../content/assets/complejos/complejo14.jpeg"
import Odontologia1 from "../../content/assets/complejos/odontologia.jpeg"
import Podologia from "../../content/assets/complejos/podologia.jpg"
import Odontologia2 from "../../content/assets/complejos/odontologia2.jpg"
import Gym1 from "../../content/assets/complejos/gym.jpeg"
import Gym2 from "../../content/assets/complejos/gym1.jpeg"

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: MontserratRegular;
  src: url(${require("../../content/assets/fonts/Montserrat-Regular.ttf")});
}
@font-face {
  font-family: MontserratBold;
  src: url(${require("../../content/assets/fonts/Montserrat-SemiBold.ttf")});
}
@font-face {
  font-family: LeagueSpartanBold;
  src: url(${require("../../content/assets/fonts/LeagueSpartan-Bold.otf")});
}
  a{
    text-decoration: none!important;
  }
`

class Beneficios extends React.Component {
  render() {
    const { data } = this.props

    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <GlobalStyles />
        <BeneficiosContainer
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "inital",
            paddingRight: 40,
            paddingLeft: 40,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <div>
              <SubTitle>Sede Principal: Guayabos 1606 Esq Roxlo</SubTitle>
              <SubTitle style={{ textAlign: "start", paddingTop: 40 }}>
                Beneficios e Instalaciones
              </SubTitle>
              <SubSubTitle>
                Alojamiento:
                <br /> Habitaciones individuales y de hasta cuatro personas{" "}
                <br />
                Baños completos
                <br />
                Cocina completa
                <br />
                Comedor
                <br />
                Parrillero
                <br />
                Cuadra de mujeres y hombres de hasta 6 personas <br />
                Sala de conferencias
              </SubSubTitle>
              <div
                className="row-mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 40,
                  marginTop: 40,
                  width: "100%",
                }}
              >
                <Image style={{ marginRight: "1rem" }} src={ImageComp1} />
                <Image  style={{ marginRight: "1rem" }} src={ImageComp9} />
              <Image src={ImageComp14} />

              </div>
              <div
                className="row-mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 40,
                  marginTop: 40,
                  width: "100%",
                }}
              >
                <Image style={{ marginRight: "1rem" }} src={ImageComp5} />
              <Image  style={{marginRight: "1rem"}} src={ImageComp12} />

                <Image style={{ marginRight: "1rem" }} src={ImageComp13} />
                <Image  src={ImageComp10} />

              </div>
              <SubSubTitle
                style={{
                  textAlign: "start",
                }}
              >
                Clases de Karate, Pilates y Gimnasia Adultos Mayores.
              </SubSubTitle>
              <div
                className="row-mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 40,
                  marginTop: 40,
                  width: "100%",
                }}
              >
                <Image style={{ marginRight: "1rem" }} src={Gym1} />
                <Image src={Gym2} />
              </div>

              <SubSubTitle
                style={{
                  textAlign: "start",
                }}
              >
                Podología gratis para socios a cargo de la Of. Ayte (R) Tec.
                Stella Camejo.
              </SubSubTitle>
              {/* 
                */}

              <div
                className="row-mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 40,
                  marginTop: 40,
                  width: "100%",
                }}
              >
                <Image style={{ marginRight: "1rem" }} src={Podologia} />
              </div>
                <SubSubTitle
                  style={{
                
                    textAlign: "start",
                  }}
                >
                  Odontología a cargo del Odontólogo Álvaro Barboni.
                </SubSubTitle> 
              <div
                className="row-mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 40,
                  marginTop: 40,
                  width: "100%",
                }}
              >
                <Image style={{ marginRight: "1rem" }} src={Odontologia1} />
                <Image style={{ marginRight: "1rem" }} src={Odontologia2} />
              </div>
                  <SubSubTitle>
                    En relación a los aranceles de la asociación odontológica del
                    Uruguay el socio tendrá un descuento de entre 30% y 40 %.
                    <br />
                    Servicio Rayos X propio, cirugías, implantes. <br />
                    Primer consulta sin costo. 
                  </SubSubTitle>
            </div>
            <div>
              <SubTitle>Complejo: Camino Sanguinetti 2809 Esq Ruta 1</SubTitle>
              <SubSubTitle>
                Casa de campo completa para 5 personas, con dos dormitorios,
                living, cocina comedor, conexión Wifi, piscina, parrillero
                exterior y excelente entorno.
                <br/>
                Por más información de disponibilidad, comodidades, alquiler y costo comuníquese con nosotros.
              </SubSubTitle>
              <div
                className="row-mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 40,
                  marginTop: 40,
                  width: "100%",
                }}
              >
                <Image style={{ marginRight: "1rem" }} src={ImageComp2} />
                <Image style={{ marginRight: "1rem" }} src={ImageComp3} />
                <Image style={{ marginRight: "1rem" }} src={ImageComp6} />
                <Image style={{ marginRight: "1rem" }} src={ImageComp7} />
                <Image src={ImageComp8} />
              </div>
            </div>
          </div>
        </BeneficiosContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            paddingRight: 40,
            paddingLeft: 40,
            marginBottom: 40,
            paddingBottom: 40,
          }}
        >
          <Title>Convenios</Title>
          <a
            style={{ textAlign: "start" }}
            href="http://brisasdelrio.uy/spa/habitaciones-2/"
            target="_blank"
          >
            Termas de Dayman SALTO – Apart Hotel Brisas del Rio
          </a>
          <SubSubTitle>
            Convenios Turísticos con Federación de Círculos de Oficiales de
            Policía en Argentina: Con hasta 5 días gratis de estadía para socios y 7 días para socios recién casados<br />
            <br />
            Círculos de Oficiales de la Provincia de Catamarca
            <br />
            Mutual de Retirados de la Policía de Catamarca
            <br />
            Círculos de Oficiales de la Provincia de Córdoba
            <br />
            Círculos de Oficiales de la Provincia de Chaco
            <br />
            Círculos de Oficiales de la Provincia de Corrientes
            <br />
            Círculos de Oficiales de la Provincia de Entre Ríos
            <br />
            Círculos de Oficiales de la Provincia de Formosa
            <br />
            Círculos de Oficiales de la Provincia de Jujuy
            <br />
            Círculos de Oficiales de la Provincia de la Rioja
            <br />
            Círculos de Oficiales de la Provincia de Santa Fe
            <br />
            Círculos de Oficiales de la Provincia de Rosario
            <br />
            Círculos de Oficiales de la Provincia de Mendoza
            <br />
            Círculos de Oficiales de la Provincia de Salta
            <br />
            Círculos de Oficiales de la Provincia de la Pampa
            <br />
            Círculos de Oficiales de la Provincia de San Juan
            <br />
            Círculos de Oficiales de Santiago del Estero
            <br />
            Círculos de Oficiales de San Luis
            <br />
            Círculos de Oficiales de la Provincia de Buenos Aires (Hotel en la
            Plata y Mar del Plata)
            <br />
            Círculos de Oficiales de la Provincia de Chubut
            <br />
            Círculos de Oficiales de la Provincia de Santa Cruz
            <br />
            Círculos de Oficiales de la Provincia de Tierra de Fuego
            <br />
            Circulo de Suboficiales de Policía Federal
            <br />
          </SubSubTitle>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingRight: 40,
            paddingLeft: 40,
            marginBottom: 40,
            backgroundColor: theme.themeColor,
            paddingBottom: 40,
          }}
        >
          <Title style={{ color: "#fff" }}>Quiero ser socio</Title>

          <Link
            style={{
              backgroundColor: "#fff",
              color: theme.themeColor,
              marginTop: 20,
            }}
            href="/socio"
          >
            Asociarme
          </Link>
        </div>
      </Layout>
    )
  }
}

// :

const BeneficiosContainer = styled.div`
  @media (max-width: 768px) {
    .row-mobile {
      flex-wrap: wrap;
    }
    img {
      width: 15rem;
    }
  }
`

const Title = styled.h1`
  font-family: LeagueSpartanBold;
  color: ${theme.themeColor};
  margin-top: 40px;
  margin-bottom: 20px;

  text-align: center;
`

const Link = styled.a`
  font-family: MontserratRegular !important;
  text-align: center;
  text-decoration: none;
  padding: 1rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border: 1px solid ${theme.themeColor};
  background-color: ${theme.themeColor};
  color: #fff;
  :hover {
    background-color: ${theme.secondaryColor}!important;
    transition: 1s;
  }
`

const Image = styled.div`
  width: 100%;
  height: 15rem;
  background-image: url(${props => props.src});
  height: 30rem;
  background-position: center center;
  background-size: cover;
  @media (max-width: 768px) {
    margin-bottom: 40px;
    margin-right: 0px !important;
  }
`

const SubTitle = styled.h3`
  font-family: LeagueSpartanBold;
  color: ${theme.themeColor};
  margin-top: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center;
  }
`

const SubSubTitle = styled.h4`
  font-family: MontserratRegular;
  font-size: 20px;
  letter-spacing: 1px;
  color: ${theme.themeColor};
  margin-top: 40px;
  margin-bottom: 20px;
  text-transform: initial;
  line-height: 36px;
  @media (max-width: 768px) {
    font-size: 8px;
    text-align: center;
  }
`

const SearchContainer = styled.div`
  height: 88vh;
  margin: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  background-image: url(${HomeBg});
  background-size: cover;
  background-position: center center;
  img {
    padding-top: 5rem;
  }
  h3 {
    color: #fff;
    font-family: LeagueSpartanBold;
    font-size: 25px;
  }
  @media (max-width: 768px) {
    .row-mobile {
      flex-wrap: wrap;
    }
    background-image: url(${HomeBg});
    h3 {
      font-size: 15px;
      text-align: center;
      padding: 3rem;
    }
    img {
      width: 15rem;
    }
  }
`

export default Beneficios

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx {
      nodes {
        frontmatter {
          date
          description
          path
          title
        }
      }
    }
  }
`
